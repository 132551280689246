import { FC, useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { AddMember } from '../../components/add-member';
import { StartMessageIcon } from '../../components/icons/start-message';
import { useStartPageStyles } from './start-page.styles';

export const StartPage: FC = observer(() => {
  const classes = useStartPageStyles();
  const [isOpenCreateGroup, setIsOpenCreateGroup] = useState(false);
  const appStore = useContext(appStoreContext);
  return (
    <div className={classes.startPage}>
      <div className={classes.container}>
        <StartMessageIcon />
        <p className={classes.title}>Выберите чат,<br />чтобы начать общение</p>
      </div>
      <AddMember
        isCreate
        isOpen={isOpenCreateGroup}
        setIsOpen={setIsOpenCreateGroup}
      />
    </div>
  );
});
