import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | 'divider'
  | 'chatList'
  | 'userInfo'
  | 'dateContainer'
  | 'dateText'
  | 'messageText'
  | 'messageTextActive'
  | 'nameActive'
  | 'chatItemActive'
  | 'chatItem'
  | 'name'
  | 'avatar'
  | 'isGroup'
  | 'startPage'
  | 'container'
  | 'title'
  | 'createChatText'
  | 'createButton'
  | 'createText'
  | 'startMessageIcon'
  | 'addUserIcon'
  | 'isChatVisible';

export const useChatListStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  chatList: {
    borderRight: '1px solid #E4E7F2',
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  isChatVisible: {
    overflowY: 'hidden',
  },
  chatItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.375rem',
    color: 'rgba(59, 65, 104, 1)',
    textDecoration: 'none',
    position: 'relative',
    paddingTop: 8,
    height: 60,
    // paddingBottom: 8,
  },
  chatItemActive: {
    composes: '$chatItem',
    backgroundColor: 'rgba(73, 61, 210, 0.10)',
  },
  avatar: {
    width: '2.875rem',
    height: '2.875rem',
    borderRadius: 100,
  },
  name: {
    fontSize: '0.875rem',
    color: '#000',
    lineHeight: '0',
    fontWeight: 600,
  },
  userInfo: {
    marginLeft: '0.5rem',
    alignSelf: 'baseline',
    width: '100%',
  },
  nameActive: {
    composes: '$name',
    color: '#000',
  },
  messageText: {
    width: '79%',
    color: '#3B4168',
    overflowWrap: 'anywhere',
    wordBreak: 'break-all',
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
  messageTextActive: {
    composes: '$messageText',
    color: '#717278',
  },
  dateContainer: {
    position: 'absolute',
    right: '1rem',
    top: 3,
  },
  dateText: {
    color: '#909EBB',
    lineHeight: 0,
    fontSize: '0.75rem',
    fontFamily: 'Manrope',
  },
  dateTextActive: {
    color: '#FFFFFF',
    lineHeight: 0,
    fontSize: '0.75rem',
    opacity: 0.5,
  },
  divider: {
    height: 1,
    background: theme.colorSecondary,
    marginTop: 4,
    marginBottom: 4,
    marginLeft: 10,
    marginRight: 10,
  },
  isGroup: {
    display: 'none',
  },
  startMessageIcon: {
    display: 'none',
  },
  container: {
    width: '100%',
    position: 'absolute',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 320,
    marginTop: 30,
  },
  title: {
    fontWeight: 600,
    margin: 0,
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontSize: '20px',
    lineHeight: '24px',
    color: '#12152A',
  },
  createChatText: {
    margin: 0,
    marginTop: 6,
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#6F7C98',
    maxWidth: '284px',
  },
  startPage: {
    position: 'relative',
    width: '100%',
  },
  addUserIcon: {
    filter: 'invert(40%) sepia(69%) saturate(5540%) hue-rotate(191deg) brightness(88%) contrast(101%);',
  },
  createButton: {
    color: '#0071CE',
    height: 16,
    fontWeight: 500,
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    gap: 5,
  },
  createText: {
    color: '#0071CE',
    height: 20,
    fontWeight: '500',
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '@media (max-width: 768px)': {
    container: {
      marginTop: 90,
    },
    chatList: {
      border: 'none',
      width: '90%',
      margin: '0 auto',
    },
    startMessageIcon: {
      display: 'inline-block',
    },
    chatItem: {
      width: '99%',
      paddingTop: 1,
      paddingBottom: 1,
    },
    messageText: {
      width: '100%',
    },
    divider: {
      width: '100%',
      marginRight: '22px',
    },
  },

  '@media (max-width: 375px)': {
    container: {
      marginTop: 70,
      marginLeft: -10,
    },
    chatList: {
      width: '99%',
      height: '99%',
      overflow: 'hidden',
    },
    chatItem: {
      width: '89.6%',
    },
    messageText: {
      width: '83%',
    },
    divider: {
      width: '89.6%',
    },
    createButton: {
      border: '1px solid #007BE0',
      position: 'absolute',
      height: '44px',
      width: '80%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 10,
      borderRadius: 4,
      cursor: 'pointer',
      marginTop: 430,
      '&:hover': {
        opacity: 0.8,
      },
    },
  },
}));
