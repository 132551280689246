import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | 'divider'
  | 'notFoundContainer'
  | 'notFoundText'
  | 'userContainer'
  | 'userAvatar'
  | 'userName'
  | 'searchContainer'
  | 'dropdownContainer'
  | 'searchInput';

export const useSearchStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  searchContainer: {
    margin: '1.03rem',
    position: 'relative',
    zIndex: 1,
    marginTop: 30,
  },
  searchInput: {
    width: '80%',
    paddingLeft: '1rem',
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    background: 'none',
    border: 'none',
    color: '#12152A',
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
    '&::placeholder': {
      color: '#909EBB',
    },
  },
  dropdownContainer: {
    position: 'absolute',
    top: '2.813rem',
    maxHeight: '18.75rem',
    backgroundColor: '#fff',
    borderRadius: 8,
    boxShadow: '0px 4px 15px rgba(33, 36, 55, 0.12)',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colorSecondary,
    padding: '0.625rem',
    width: 'calc(100% - 1.25rem)',
    overflowY: 'scroll',
  },
  notFoundContainer: {
    height: 90,
    textAlign: 'center',
    paddingTop: 32,
  },
  notFoundText: {
    fontFamily: 'Manrope',
    fontSize: '1rem',
    fontWeight: 400,
    color: theme.colorFont,
    margin: 0,
    marginTop: 10,
  },
  userContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '2.75rem',
    marginBottom: 4,
    '&:hover': {
      backgroundColor: '#0071CE',
      color: '#fff',
    },
    '&:hover $userName': { color: '#fff' },
    paddingLeft: '0.375rem',
    paddingRight: '0.375rem',
    borderRadius: 8,
    cursor: 'pointer',
    textDecoration: 'none',
  },
  userAvatar: {
    width: '2.375rem',
    height: '2.375rem',
    borderRadius: 50,
    filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.11))',
    border: '1px solid #FFFFFF',
  },
  userName: {
    color: '#3B4168',
    fontSize: '1rem',
    marginLeft: '0.625rem',
    cursor: 'pointer',
    fontWeight: 500,
  },
  divider: {
    position: 'absolute',
    bottom: -2,
    left: 0,
    width: '100%',
    height: 1,
    backgroundColor: '#E4E7F2',
  },
  '@media (max-width: 768px)': {
    searchContainer: {
      width: '98.8%',
      marginLeft: 6,
    },
  },
  '@media (max-width: 375px)': {
    searchContainer: {
      width: '91%',
      marginLeft: 6,
    },
  },
}));
