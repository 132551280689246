import { FC, SVGProps } from 'react';

export const NoAvatarIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M12.0044 21.6004C10.4032 21.6004 8.81086 21.1546 7.59678 20.2629C5.46774 18.6928 5.46774 16.1341 7.59678 14.5737C10.0161 12.7904 13.9839 12.7904 16.4033 14.5737C18.5323 16.1341 18.5323 18.6928 16.4032 20.2629C15.1891 21.1546 13.5968 21.6004 11.9956 21.6004M12.1536 10.9972C12.0656 10.9875 11.96 10.9875 11.8633 10.9972C9.76943 10.9196 8.10667 9.02971 8.10667 6.70363C8.09787 4.3291 9.84861 2.40039 12.004 2.40039C14.1595 2.40039 15.9102 4.3291 15.9102 6.70363C15.9102 9.02971 14.2386 10.9196 12.1536 10.9972Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
