import { DefaultEventsMap } from '@socket.io/component-emitter';
import { makeAutoObservable } from 'mobx';
import { io, Socket } from 'socket.io-client';
import { IChat } from '../models/stores/chatList.store';
import { IMessage } from '../models/stores/conversation.store';
import { IRootStore } from '../models/stores/root.store';
import { ISocketStore } from '../models/stores/socket.store';

export class SocketStore implements ISocketStore {

  public readonly rootStore: IRootStore;

  public socket?: Socket<DefaultEventsMap, DefaultEventsMap> | undefined;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public connectSocket(): void {
    if (!this.socket) {
      this.socket = io(`${this.rootStore.config.websocketURL}`, {
        auth: {
          authorization: this.rootStore.user.token,
        },
        path: this.rootStore.config.websocketPath,
        transports: ['websocket'],
      });
      this.socket.connect();
      this.socket.on('messageToClient', (newMessage: IMessage) => {
        if (newMessage.chat_id === this.rootStore.conversation.chatId) {
          this.rootStore.conversation.messages.push(newMessage);
        }
        const chatIndex = this.rootStore.chatList.list.findIndex((item) => item.chatId === newMessage.chat_id);
        if (chatIndex !== -1) {
          this.rootStore.chatList.list[chatIndex].lastMessage = newMessage;
          this.rootStore.chatList.list = this.rootStore.chatList.list.sort((a: IChat, b: IChat) => (new Date(b.lastMessage?.updatedAt || '0')).getTime() - new Date(a.lastMessage?.updatedAt || '0').getTime());
        }
        else {
          this.rootStore.chatList.getChatList();
        }
      });
      this.socket.on('chatCreatedToClient', this.rootStore.chatList.getChatList);
    }
  }

}
