import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | 'title'
  | 'createChatLink'
  | 'createChatText'
  | 'container'
  | 'startPage'
  ;

export const useStartPageStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  title: {
    color: 'var(--text-secondary, #717278)',
    textAlign: 'center',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Manrope',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
  },
  createChatText: {
    margin: 0,
    marginTop: 6,
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#3B4168',
  },
  createChatLink: {
    color: ' #0071CE',
    cursor: 'pointer',
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
  },
  startPage: {
    position: 'relative',
  },
  '@media (max-width: 768px)': {
    startPage: {
      display: 'none',
    },
  },
}));
