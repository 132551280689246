import { FC } from 'react';
import cn from 'classnames/bind';
import { NoAvatarIcon } from '../icons/no-avatar';
import { useNoAvatarStyles } from './noAvatar.styles';

export const NoAvatar: FC<{ search?: boolean }> = ({ search }) => {
  const classes = useNoAvatarStyles();
  const cx = cn.bind(classes);
  const avatarClassName = cx(classes.container, {
    search,
  });

  return (
    <div className={avatarClassName}>
      <NoAvatarIcon width={24} height={24} />
    </div>
  );
};
