import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | 'modal'
  | 'saveButton'
  | 'editContainer'
  | 'flex'
  | 'header'
  | 'divider'
  | 'title'
  | 'closeIcon'
  | 'wrapper'
  | 'flexCenter';

export const useModalStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    width: '50%',
    maxHeight: 'max-content',
    maxWidth: 'calc(726px - 4rem)',
    minHeight: '10%',
    overflow: 'auto', /* Recommended in case content is larger than the container */
    margin: 'auto', /* Center the item vertically & horizontally */
    position: 'absolute', /* Break it out of the regular flow */
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 999999999,
    background: '#fff',
    boxShadow: '0px 4px 15px rgba(33, 36, 55, 0.12)',
    borderRadius: 8,
    padding: '2rem',
    height: 'max-content',
  },
  wrapper: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    // alignItems: 'center',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: '600',
    color: '#12152A',
    marginTop: 0,
    // margin: 0,
    // marginTop: -16,
  },
  closeIcon: {
    cursor: 'pointer',
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: '#E4E7F2',
    marginBottom: '2rem',
  },
  editContainer: {
    width: '2rem',
    height: '2rem',
    borderRadius: 100,
    marginLeft: '0.625rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(228, 231, 242, 0.5)',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  flex: {
    display: 'flex',
  },
  saveButton: {
    color: '#0071CE',
    marginRight: '1.625rem',
    cursor: 'pointer',
  },
  '@media (max-width: 576px)': {
    modal: {
      position: 'fixed',
      boxSizing: 'border-box',
      width: '80%',
    },
  },
}));

export const maskStyle = {
  background: 'rgba(228, 231, 242, 0.4)',
  backdropFilter: 'blur(5px)',
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 999999998,
};
