import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | 'clickable'
  | 'leftSide'
  | 'avatar'
  | 'isAdmin'
  | 'isAdminTitle'
  | 'redButton'
  | 'iconContainer'
  | 'title'
  | 'listItem';

export const useUserItemStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  listItem: {
    borderRadius: 8,
    background: '#fff',
    border: '1px solid #E4E7F2',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '3.375rem',
    paddingLeft: '0.875rem',
    paddingRight: '0.875rem',
    marginBottom: '0.5rem',
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: '1rem',
    color: '#3B4168',
    marginLeft: '0.875rem',
  },
  iconContainer: {
    width: '2.375rem',
    height: '2.375rem',
    background: '#73AAFF',
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  redButton: {
    background: 'rgba(235, 87, 87, 0.1)',
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
  avatar: {
    width: '2.375rem',
    height: '2.375rem',
    borderRadius: 100,
  },
  isAdmin: {
    height: '1.5rem',
    borderRadius: 6,
    background: '#73AAFF',
    padding: '4px 8px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0.625rem',
  },
  isAdminTitle: {
    color: '#fff',
    marginLeft: '0.5rem',
  },
  clickable: {
    cursor: 'pointer',
  },
}));
