import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | 'fileIcon'
  | 'fileIconOwner'
  | 'download'
  | 'downloadOwner'
  | 'username'
  | 'usernameOwner'
  | 'chatMsgImg'
  | 'choosenMessage'
  | 'chatMsgContentOwner'
  | 'chatMsgProfile'
  | 'chatMsgTextOwner'
  | 'chatMsg'
  | 'owner'
  | 'chatMsgDate'
  | 'chatMsgContent'
  | 'chatMsgDateOwner'
  | 'chatMsgText';

export const useMessageStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  chatMsgImg: {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  chatMsgProfile: {
    flexShrink: '0',
    position: 'relative',
    top: -24,
  },
  chatMsgDate: {
    fontSize: '0.875rem',
    fontWeight: '400',
    color: '#9D9DAC',
    whiteSpace: 'nowrap',
    paddingLeft: 16,
    paddingRight: 16,
  },
  chatMsgDateOwner: {
    composes: '$chatMsgDate',
    color: 'rgba(255,255,255,0.5)',
  },
  chatMsg: {
    display: 'flex',
    padding: '0 20px 24px',
    width: '100%',
  },
  chatMsgContent: {
    marginLeft: '12px',
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: 8,
    position: 'relative',
  },
  chatMsgText: {
    color: '#3B4168',
    paddingTop: 6,
    borderRadius: '20px 20px 20px 0',
    lineHeight: '1.5',
    fontSize: '14px',
    overflowWrap: 'anywhere',
    fontFamily: 'Manrope',
    fontWeight: 400,
    wordBreak: 'break-all',
  },
  chatMsgTextOwner: {
    composes: '$chatMsgText',
    color: '#fff',
  },
  chatMsgContentOwner: {
    composes: '$chatMsgContent',
    backgroundColor: '#0071CE',
    marginLeft: '0',
    marginRight: '12px',
    alignItems: 'flex-end',
    width: '100%',
  },
  owner: {
    flexDirection: 'row-reverse',
    'chat-msg-text': {
      backgroundColor: 'var(--theme-color)',
      color: '#fff',
      borderRadius: '20px 20px 0 20px',
    },
    'chat-msg-date': {
      left: 'auto',
      right: 'calc(100% + 12px)',
    },
  },
  choosenMessage: {
    backgroundColor: 'rgba(0, 113, 206, 0.07)',
    borderRadius: 8,
    position: 'relative',
  },
  username: {
    position: 'absolute',
    top: -24,
    width: 1000,
    textAlign: 'left',
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '1rem',
    color: '#282833',
    display: 'flex',
    alignItems: 'center',
  },
  usernameOwner: {
    composes: '$username',
    right: 0,
    textAlign: 'right',
  },
  '@media (max-width: 375px)': {
    chatMsgText: {
      padding: '6px 10px 0px 10px',
    },
    chatMsgTextOwner: {
      padding: '6px 10px 0px 10px',
    },
  },
  download: {
    display: 'grid',
    gridTemplateColumns: '44px 4fr',
    alignItems: 'center',
    color: theme.colorFont,
  },
  downloadOwner: {
    composes: '$download',
    color: theme.colorWhite,
  },
  fileIcon: {
    width: 36,
    height: 36,
    background: 'rgba(0, 113, 206, 0.07)',
    borderRadius: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 6,
  },
  fileIconOwner: {
    composes: '$fileIcon',
    background: 'rgba(255, 255, 255, 0.1)',
  },
}));
