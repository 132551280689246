import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | 'button'
  | 'buttonContainer'
  | 'buttonText'
  | 'primaryText'
  | 'warningText'
  | 'primaryButton'
  | 'warningButton'
  | 'leftSide'
  | 'link'
  | 'sectionTitle'
  | 'iconContainer'
  | 'title'
  | 'listItemActive'
  | 'listItem';

export const useEditGroupStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  listItem: {
    borderRadius: 8,
    background: '#F5F6F8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '3.375rem',
    paddingLeft: '0.875rem',
    paddingRight: '0.875rem',
    cursor: 'pointer',
    marginBottom: '0.5rem',
  },
  listItemActive: {
    composes: '$listItem',
    '&:hover': {
      opacity: 0.8,
    },
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: '1rem',
    color: '#3B4168',
    marginLeft: '0.875rem',
  },
  iconContainer: {
    width: '2.375rem',
    height: '2.375rem',
    background: '#73AAFF',
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionTitle: {
    fontSize: '1.25rem',
    color: '#3B4168',
    fontWeight: '600',
    marginTop: '1.5rem',
    marginBottom: 14,
  },
  link: {
    fontSize: '1rem',
    color: '#0071CE',
    '&:hover': {
      opacity: 0.8,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '1.5rem',
  },
  button: {
    width: '11.6rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    marginBottom: '0.5rem',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  buttonText: {
    fontSize: '1rem',
    fontWeight: '500',
  },
  primaryButton: {
    composes: '$button',
    background: 'linear-gradient(94.25deg, #007BE0 -0.43%, #0058A0 54.69%)',
  },
  warningButton: {
    composes: '$button',
    border: '1px solid #EB5757',
    marginLeft: '1.25rem',
  },
  primaryText: {
    composes: '$buttonText',
    color: '#fff',
  },
  warningText: {
    composes: '$buttonText',
    color: '#EB5757',
  },
  '@media (max-width: 768px)': {
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    warningButton: {
      boxSizing: 'border-box',
      width: '100%',
      margin: 0,
    },
    primaryButton: {
      width: '100%',
    },
  },
}));
