import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | 'date'
  | 'conversationContainer'
  | 'anchor'
  | 'messagesContainer'
  | 'startPage'
  | 'container'
  | 'title';

export const useConversationStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  conversationContainer: {
    position: 'relative',
  },
  messagesContainer: {
    position: 'absolute',
    width: '100%',
    height: 'calc(100% - 7.5rem)',
    overflowY: 'scroll',
    paddingTop: '3.75rem',
    overflowAnchor: 'none',
  },
  anchor: {
    overflowAnchor: 'auto',
    height: 1,
  },
  startPage: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '30%',
  },
  container: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 600,
    margin: 0,
    marginTop: '1rem',
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontSize: '20px',
    lineHeight: '24px',
    color: '#12152A',
  },
  date: {
    fontFamily: 'Manrope',
    fontSize: '1rem',
    color: theme.colorGreyText,
    textAlign: 'center',
    margin: 0,
    fontWeight: '400',
    marginBottom: 44,
  },
  '@media (max-width: 1280px)': {
    messagesContainer: {
      paddingTop: 16,
      height: 'calc(100% - 3.5rem)',
    },
  },
  '@media (max-width: 768px)': {
    messagesContainer: {
      height: 'calc(100% - 7.5rem)',
      marginTop: 60,
      paddingTop: 16,
    },
    date: {
      marginBottom: 20,
    },
  },
  '@media (max-width: 375px)': {
    messagesContainer: {
      height: 'calc(100% - 6.5rem)',
      marginTop: 50,
    },
  },
}));
