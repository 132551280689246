import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | 'search'
  | 'container';

export const useNoAvatarStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  container: {
    background: '#AE4CFB',
    border: '2px solid #FFFFFF',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.11)',
    borderRadius: 100,
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  search: {
    width: 34,
    height: 34,
  },
}));
