import { FC, SVGProps } from 'react';

export const StartMessageIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_12843_82839)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.248 80.4682C117.404 70.5773 121.45 58.5317 119.594 46.2791C115.594 19.8687 85.6877 2.49737 52.7963 7.47903C19.9049 12.4607 -3.51613 37.9089 0.48392 64.3192C4.48397 90.7296 34.3904 108.101 67.2818 103.119C73.1522 102.23 78.7209 100.689 83.8859 98.607L109.248 113.25L109.248 80.4682Z"
        fill="#7B68EE"
        fillOpacity="0.14"
      />
      <circle
        cx="49.6179"
        cy="57.5583"
        r="2.37377"
        fill="#7B68EE"
        fillOpacity="0.4"
      />
      <circle
        cx="60.3"
        cy="57.5583"
        r="2.37377"
        fill="#7B68EE"
        fillOpacity="0.4"
      />
      <circle
        cx="70.9817"
        cy="57.5583"
        r="2.37377"
        fill="#7B68EE"
        fillOpacity="0.4"
      />
    </g>
    <defs>
      <clipPath id="clip0_12843_82839">
        <rect width="120" height="120" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
