import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | 'main';

export const useMainStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  main: {
    padding: '30px 12.5rem 30px 12.5rem',
  },
  '@media (max-width: 768px)': {
    main: {
      paddingTop: '0px',
      height: '0px',
    },
  },
  '@media (max-width: 375px)': {
    main: {
      padding: '30px 0 30px 12.5rem',
    },
  },
}));
