import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | 'messageInputContainer'
  | 'clearButton'
  | 'addAttachmentButton'
  | 'sendButton'
  | 'inputForm';

export const useMessageInputStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  messageInputContainer: {
    background: '#fff',
    height: '3.75rem',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    width: 'calc(100% - 3.75rem)',
    paddingLeft: '1.875rem',
    paddingRight: '1.875rem',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.05)',
    borderTop: 1,
    borderTopStyle: 'solid',
    borderTopColor: '#E4E7F2',
  },
  inputForm: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: 6,
    border: '1px solid #EAEAF1',
    // Добавим стили для фокуса
    '&:focus-within': {
      borderColor: '#AE4CFB',
    },
  },
  clearButton: {
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    border: 'none',
    cursor: 'pointer',
    overflow: 'hidden',
    outline: 'none',
  },
  sendButton: {
    composes: '$clearButton',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '1.25rem',
  },
  addAttachmentButton: {
    composes: '$clearButton',
    border: '1px solid #E4E7F2',
    borderRadius: 20,
    width: '2.375rem',
    height: '2.375rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '1.25rem',
  },
}));
