import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | 'containerChecked'
  | 'container';

export const useCheckboxStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  container: {
    width: '1rem',
    height: '1rem',
    borderRadius: 2,
    border: '1px solid #E4E7F2',
    background: '#fff',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerChecked: {
    composes: '$container',
    background: '#0071CE',
    border: 'none',
  },
}));
