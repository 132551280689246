import { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames/bind';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { observer } from 'mobx-react';
import { IChat } from '../../../../models/stores/chatList.store';
import { appStoreContext } from '../../../../stores/context.store';
import { Avatar } from '../../avatar';
import { useChatListStyles } from '../chatList.styles';

export const Chat = observer(({ item }: { item: IChat }) => {
  const appStore = useContext(appStoreContext);
  const classes = useChatListStyles();
  const cx = cn.bind(classes);
  const formatedDate = item.lastMessage ? format(new Date(item.lastMessage?.createdAt), 'dd.MMM', { locale: ru }) : null;
  const isActive = appStore.conversation.chatId === item.chatId;
  const chatItemClass = cx({
    chatItemActive: isActive,
    chatItem: !isActive,
  });
  const chatNameClass = cx({
    nameActive: isActive,
    name: !isActive,
  });
  const chatMessageText = cx({
    messageTextActive: isActive,
    messageText: !isActive,
  });
  return (
    <Fragment key={item.chatId}>
      <Link className={chatItemClass} to={`/messenger/${item.chatId}`}>
        <div style={{ width: 44, marginLeft: 12 }}>
          <Avatar
            membersCount={item.members.length}
            src={item.avatar}
            isGroup={item.isGroup}
          />
        </div>
        <div className={classes.userInfo}>
          <div>
            <p className={chatNameClass}>{item.name}</p>
            <div className={classes.dateContainer }>
              <p className={classes.dateText}>{formatedDate}</p>
            </div>
          </div>
          {
            item.lastMessage && (
              <p className={chatMessageText}>
                {
                  item.lastMessage.message?.slice(0, 50)
                }
              </p>
            )
          }
        </div>
      </Link>
    </Fragment>
  );
});
