import { useContext } from 'react';
import { formatRelative } from 'date-fns';
import { ru } from 'date-fns/esm/locale';
import { observer } from 'mobx-react';
import { IUsers } from '../../../../models/stores/search.store';
import { appStoreContext } from '../../../../stores/context.store';
import { Message } from '../../../components/message';
import { FormatRelativeType } from '../conversation.models';
import { useConversationStyles } from '../conversation.styles';
import { IProps } from './types';

const otherDates = 'd MMMM';

const formatRelativeLocale = {
  lastWeek: otherDates,
  yesterday: "'Вчера'",
  today: "'Сегодня'",
  tomorrow: otherDates,
  nextWeek: otherDates,
  other: otherDates,
};

const locale = {
  ...ru,
  formatRelative: (token: FormatRelativeType): string => formatRelativeLocale[token],
};

export const Messages = observer(({ message, index }: IProps) => {
  const appStore = useContext(appStoreContext);
  const classes = useConversationStyles();
  const { owner, users, messages } = appStore.conversation;
  const { sender_id, createdAt, _id, attachment } = message;
  let username: string | undefined = '';
  const member: IUsers | undefined = users?.find((item) => item.id === sender_id);
  const isOwner: IUsers | undefined = (owner?.id === sender_id ? owner : undefined);
  username = member?.name || isOwner?.name || undefined;
  const avatar = member?.avatar || isOwner?.avatar || undefined;
  const prevDate = new Date(messages[index - 1]?.createdAt).getDate();
  const date = new Date(createdAt).getDate() !== prevDate &&
    formatRelative(new Date(createdAt), new Date(), { locale });

  return (
    <>
      {
        date && (
          <p className={classes.date}>{date}</p>
        )
      }
      <Message
        attachment={attachment}
        username={username}
        from={sender_id}
        text={message.message}
        timestamp={createdAt}
        key={_id}
        messageId={_id}
        avatar={avatar}
      />
    </>
  );
});
