import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | 'groupInput'
  | 'divider'
  | 'button'
  | 'buttonContainer'
  | 'primaryText'
  | 'primaryButton'
  | 'cancelButton'
  | 'cancelText'
  | 'button'
  | 'users';

export const useAddMemberStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  users: {
    marginTop: '1.5rem',
    overflowY: 'scroll',
    maxHeight: 300,
  },
  buttonContainer: {
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    width: '11.6rem',
    height: '2.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    marginBottom: '0.5rem',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  buttonText: {
    fontSize: '1rem',
    fontWeight: '500',
  },
  primaryButton: {
    composes: '$button',
    background: 'linear-gradient(94.25deg, #007BE0 -0.43%, #0058A0 54.69%)',
    marginLeft: '1.25rem',
    height: 48,
  },
  cancelButton: {
    composes: '$button',
    position: 'relative',
    display: 'inline-block',
    padding: 2,
    borderWidth: 1,
    borderColor: '#0071CE',
    borderStyle: 'solid',
  },
  primaryText: {
    composes: '$buttonText',
    color: '#fff',
  },
  cancelText: {
    composes: '$buttonText',
    color: '#0071CE',
    textAlign: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
  },
  groupInput: {
    marginBottom: '1rem',
  },
  divider: {
    height: '1px',
    width: '100%',
    background: '#E4E7F2',
    marginTop: '1rem',
  },
  '@media (max-width: 768px)': {
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cancelButton: {
      boxSizing: 'border-box',
      width: '100%',
    },
    primaryButton: {
      margin: 0,
      width: '100%',
    },
  },
}));
