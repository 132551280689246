import { FC, PropsWithChildren } from 'react';
import { Messenger } from '../messenger';
import { useMainStyles } from './main.styles';

export const Main: FC<PropsWithChildren> = ({ children }) => {
  const classes = useMainStyles();

  return (
    <div className={classes.main}>
      <Messenger>
        {children}
      </Messenger>
    </div>
  );
};
