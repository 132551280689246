import { makeAutoObservable, runInAction } from 'mobx';
import { IFindByKey } from '../models/requests/chat.models';
import { IRootStore } from '../models/stores/root.store';
import { ISearchStore, IUsers } from '../models/stores/search.store';

export class SearchStore implements ISearchStore {

  public readonly rootStore: IRootStore;

  public loading: boolean = true;

  public isDropdownOpen: boolean = false;

  public users: IUsers[] | null = [];
  public searchString: string = '';

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public search(stringToSearch: string): void {
    this.searchString = stringToSearch;
    // try {
    //   this.loading = true;

    //   const data = await this.rootStore.requests.json<IFindByKey[]>({
    //     baseURL: this.rootStore.config.baseURL,
    //     headers: {
    //       authorization: this.rootStore.user.token || '',
    //     },
    //     method: 'GET',
    //     params: {
    //       key: stringToSearch,
    //       page: 1,
    //       limit: 50,
    //     },
    //     route: '/api/v1/chat/find-by-key',
    //   });
    //   runInAction(() => {
    //     if (data.length > 0) {
    //       // this.users = data.map((item) => ({
    //       //   name: `${item.user_info.passport.name} ${item.user_info.passport.sur_name}`,
    //       //   id: item.id,
    //       //   avatar: item.avatar && `${this.rootStore.config.s3URL}${item.avatar}`,
    //       //   phone: item.phone,
    //       // }));
    //     }
    //     else {
    //       runInAction(() => {
    //         this.users = null;
    //       });
    //     }
    //   });
    //   this.loading = false;
    // }
    // catch (error) {
    //   this.loading = false;
    // }
  }

  public clear(): void {
    this.users = null;
    this.searchString = '';
  }

  public setDropdownOpen(open: boolean): void {
    this.isDropdownOpen = open;
  }

}
