import { FC, useContext, useState } from 'react';
import cn from 'classnames/bind';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { AddMember } from '../add-member';
import { Search } from '../search';
import { Chat } from './chat/Chat';
import { IListType } from './chatList.model';
import { useChatListStyles } from './chatList.styles';
import { EmptyChat } from './emptyChat/EmptyChat';

export const ChatList: FC<IListType> = observer(() => {
  const [isGroupVisible, setIsGroupVisible] = useState(false);
  const appStore = useContext(appStoreContext);
  const classes = useChatListStyles();
  const [isOpenCreateGroup, setIsOpenCreateGroup] = useState(false);
  const chatId = appStore.conversation.chatId;
  const list = appStore.chatList.list;
  const cx = cn.bind(classes);
  const isGroupVisibleClass = cx({
    isGroup: isGroupVisible,
    isChatVisible: list.length === 0,
  });
  new ResizeObserver(() => {
    setIsGroupVisible(chatId !== null && window.innerWidth <= 768);
  }).observe(document.body);

  return (
    <div className={`${classes.chatList} ${isGroupVisibleClass}`}>
      <Search />
      <div>
        {
          list
            .filter((item) => item.name?.toLowerCase().includes(appStore.search.searchString))
            .map((item) => (
              <Chat item={item} />
            ))
        }
      </div>
    </div>
  );
});
