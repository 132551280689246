import { CloseRedIcon } from '../icons/close-red';
import { LeaveChatIcon } from '../icons/leave-chat';

export const deleteModalProps = {
  title: 'Удалить чат',
  buttonTitle: 'Удалить',
  confirmType: 'danger',
  confirmTitle: 'Вы точно уверены, что хотите удалить чат?',
  confirmDescription: 'Вся история сообщений будет удалена',
};

export const quitModalProps = {
  title: 'Выйти из чата?',
  buttonTitle: 'Выйти',
  confirmType: 'primary',
  confirmTitle: 'Покинув чат, вы не будете получать новые сообщения от участников. ',
  confirmDescription: 'Вы сможете вернуться при наличии свободных мест.',
  icon: <LeaveChatIcon />,
};
