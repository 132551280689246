import { FC, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { IUsers } from '../../../models/stores/search.store';
import { appStoreContext } from '../../../stores/context.store';
import { Modal } from '../modal';
import { SearchInput } from '../searchInput';
import { UserItem } from '../userItem';
import { IAddMember } from './addMember.model';
import { useAddMemberStyles } from './addMember.styles';

export const AddMember: FC<IAddMember> = observer(({ isOpen, setIsOpen, isCreate }) => {
  const navigate = useNavigate();
  const appStore = useContext(appStoreContext);
  const [selectedUsers, setSelectedUsers] = useState<IUsers[]>([]);
  const [groupName, setGroupName] = useState('');
  const [findUserValue, setFindUserValue] = useState('');
  const { chatId } = appStore.conversation;
  const { users } = appStore.search;
  const classes = useAddMemberStyles();

  useEffect(() => {
    if (!isOpen) {
      clear();
    }
  }, [isOpen]);

  const clear = (): void => {
    setFindUserValue('');
    setSelectedUsers([]);
    setGroupName('');
    appStore.search.clear();
  };

  const handleSearch = (value: string): void => {
    setFindUserValue(value);
    if (value.length > 2) {
      appStore.search.search(value);
    }
  };

  const addMembers = async(): Promise<void> => {
    if (isCreate && groupName && selectedUsers.length > 0) {
      if (selectedUsers.length > 1) {
        const id = await appStore.conversation.createGroup(groupName, selectedUsers.map((item) => item.id));
        if (id) {
          navigate(`messenger/${id}`);
          await appStore.conversation.setConversation(id);
          setIsOpen(false);
        }
      }
      else {
        alert('Не менее 2х человек в группе.');
      }
    }
  };

  const cancel = (): void => {
    setSelectedUsers([]);
    setIsOpen(false);
  };

  const onSelect = (user: IUsers, checked: boolean): void => {
    setSelectedUsers((prev) => {
      if (checked) {
        if (prev.findIndex((item) => item.id === user.id) === -1) {
          return [...prev, user];
        }
        return prev;
      }
      return prev.filter((item) => item.id !== user.id);
    });
  };

  const title = isCreate ? 'Создать группу' : 'Добавить участника';
  const buttonTitle = isCreate ? 'Создать чат' : 'Добавить';

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      {
        isCreate && (
          <div className={classes.groupInput}>
            <SearchInput
              noIcon
              placeholder="Введите название группы"
              value={groupName}
              onChange={(e): void => setGroupName(e.currentTarget.value)}
            />
            <div className={classes.divider} />
          </div>
        )
      }
      <SearchInput
        placeholder="Искать по чатам"
        value={findUserValue}
        onChange={(e): void => handleSearch(e.currentTarget.value)}
      />
      <div className={classes.users}>
        {
          selectedUsers && (
            selectedUsers.map((item) => <UserItem
              avatar={item.avatar}
              id={item.id}
              name={item.name}
              checkbox
              checked
              onChangeCheckbox={(checked: boolean): void => onSelect(item, checked)}
            />)
          )
        }
        {
          users && (
            users
              .filter((item) => selectedUsers.findIndex((selected) => selected.id === item.id) === -1)
              .map((item) => <UserItem
                key={item.id}
                avatar={item.avatar}
                id={item.id}
                name={item.name}
                checkbox
                checked={false}
                onChangeCheckbox={(checked: boolean): void => onSelect(item, checked)}
                clickable
              />)
          )
        }
        Не менее 2х человек
      </div>
      <div className={classes.buttonContainer}>
        <div className={classes.cancelButton} onClick={cancel}>
          <p className={classes.cancelText}>Отменить</p>
        </div>
        <div className={classes.primaryButton} onClick={addMembers}>
          <p className={classes.primaryText}>{buttonTitle}</p>
        </div>
      </div>
    </Modal>
  );
});
