import { makeAutoObservable, runInAction } from 'mobx';
import { IGetChats, IUserData } from '../models/requests/chat.models';
import { IChat, IChatListStore } from '../models/stores/chatList.store';
import { IRootStore } from '../models/stores/root.store';

export class ChatListStore implements IChatListStore {

  public readonly rootStore: IRootStore;

  public loading: boolean = true;

  public list: IChat[] = [];

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public async getChatList(): Promise<void> {
    try {
      runInAction(() => {
        this.loading = true;
      });

      let data = await this.rootStore.requests.json<IGetChats[]>({
        baseURL: this.rootStore.config.baseURL,
        headers: {
          authorization: `Bearer ${this.rootStore.user.token}` || '',
        },
        method: 'GET',
        route: '/chats',
      });
      const userIds: Set<string> = new Set();
      for (const item of data) {
        for (const member of item.members) {
          userIds.add(member);
        }
        userIds.add(item.owner);
      }

      const usersData = await this.rootStore.requests.json<IUserData[]>({
        baseURL: this.rootStore.config.baseTeamFlameURL,
        headers: {
          authorization: `Bearer ${this.rootStore.user.token}` || '',
          'X-Api-Version': 1,
        },
        method: 'GET',
        route: `users?userIds=${Array.from(userIds).filter((a) => a).join(',')}`,
      });
      data = data.map((item) => ({
        ...item,
        slug: item.members.join(','),
        count_members: item.members.length,
        last_messages: [item.lastMessage],
        owner: this.rootStore.user.user?.id || '',
      }));
      runInAction(() => {
        console.log(data);
        this.list = data.map((item) => {
          const isGroup = Boolean(item.name);
          const currentUser = usersData.find((user) => user.id === (item.members.find((u) => u !== this.rootStore.user.user?.id)));
          const name = isGroup ? item.name : `${currentUser?.firstName} ${currentUser?.lastName}`;
          const lastMessage = item.last_messages.length > 0 ? item.last_messages[item.last_messages.length - 1] : null;
          console.log(currentUser);
          return {
            chatId: item._id,
            members: item.members,
            userId: currentUser?.id || '',
            owner: item.owner,
            isGroup,
            avatar: currentUser?.avatar,
            name,
            lastMessage,
          };
        })
          // sort by last message date
          .sort((a: IChat, b: IChat) => (new Date(b.lastMessage?.createdAt || '0')).getTime() - new Date(a.lastMessage?.createdAt || '0').getTime());

        this.loading = false;
      });
    }
    catch (error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

}
