import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | '@global'
  | 'layout';

export const useLayoutStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  layout: {
    backgroundColor: '#F5F6F8',
    position: 'relative',
    height: '100vh',
    // width: '100vw',
  },
  '@global': {
    body: {
      fontFamily: "'Manrope', sans-serif",
      margin: 0,
      background: theme.colorWhiteBackground,
      color: theme.colorFont,
    },
    '*': {
      fontFamily: "'Manrope', sans-serif",
    },
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));
