import { FC, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { AddMember } from '../add-member';
import { ConfirmModal } from '../confirmModal';
import { ChatLinkIcon } from '../icons/chat-link';
import { Modal } from '../modal';
import { UserItem } from '../userItem';
import { useEditGroupStyles } from './editGroup.styles';
import { deleteModalProps, quitModalProps } from './modalProps';

export const EditGroup: FC = observer(() => {
  const navigate = useNavigate();
  const [isAddMemberOpen, setIsAddMemberOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const appStore = useContext(appStoreContext);
  const { chatId, user, editGroupIsOpen, owner, users } = appStore.conversation;
  const classes = useEditGroupStyles();

  const onRemove = (): void => {
    setIsConfirmOpen(true);
    NiceModal.show(ConfirmModal, deleteModalProps).then(async() => {
      if (chatId) {
        await appStore.conversation.deleteChat(chatId);
        navigate('/', { replace: true });
        appStore.conversation.setConversation(null);
      }
    }).finally(() => {
      setIsConfirmOpen(false);
    });
  };

  const onLeaveFromChat = (): void => {
    setIsConfirmOpen(true);
    NiceModal.show(ConfirmModal, quitModalProps)
      .then(() => {
        if (chatId) {
          appStore.conversation.exitChat(chatId);
        }
      })
      .finally(() => {
        setIsConfirmOpen(false);
      });
  };

  const handleNavigate = window.navigator.clipboard.writeText(
    `${window.location.origin}/messenger/${chatId}`,
  );

  useEffect(() => {
    appStore.conversation.openEditGroup(!(isConfirmOpen || isAddMemberOpen));
  }, [isAddMemberOpen, appStore.conversation, isConfirmOpen]);

  useEffect(() => {
    appStore.conversation.openEditGroup(false);
  }, [appStore.conversation]);

  return (
    <div>
      <Modal
        title={user?.name}
        isOpen={editGroupIsOpen}
        setIsOpen={(isOpen: boolean): void => appStore.conversation.openEditGroup(isOpen)}
        editableTitle
        onSubmitTitle={(newTitle: string): void => {
          appStore.conversation.updateGroup(
            chatId || '',
            newTitle,
            user?.avatar || 'null',
          );
        }}
      >
        <div>
          {/* TODO: add user to group */}
          {/* <a className={classes.listItemActive} onClick={(): void => setIsAddMemberOpen(true)}>
            <div className={classes.leftSide}>
              <div className={classes.iconContainer}>
                <PlusIcon />
              </div>
              <p className={classes.title}>Добавить участника</p>
            </div>
            <div>
              <ArrowRightIcon />
            </div>
          </a> */}
          <span
            onClick={(): Promise<void> => handleNavigate}
            className={classes.listItem}
          >
            <div className={classes.leftSide}>
              <div className={classes.iconContainer}>
                <ChatLinkIcon />
              </div>
              <p className={classes.title}>Ссылка на чат</p>
            </div>
            <div>
              <span className={classes.link}>Копировать ссылку</span>
            </div>
          </span>
        </div>
        <p className={classes.sectionTitle}>Участники чата</p>
        {
          owner && (
            <UserItem
              id={owner.id}
              avatar={owner.avatar}
              name={owner.name}
              isAdmin
            />
          )
        }
        {
          users && (
            users.map((item) => <UserItem
              key={item.id}
              id={item.id}
              avatar={item.avatar}
              name={item.name}
              removeButton={appStore.user.user?.id === owner?.id}
              onRemove={(): Promise<void> => appStore.conversation.removeUser([item.id])}
            />)
          )
        }
        <div className={classes.buttonContainer}>
          <div onClick={onLeaveFromChat} className={classes.primaryButton}>
            <p className={classes.primaryText}>Выйти из чата</p>
          </div>
          <div onClick={onRemove} className={classes.warningButton}>
            <p className={classes.warningText}>Удалить чат</p>
          </div>
        </div>
        <AddMember
          isOpen={isAddMemberOpen}
          setIsOpen={setIsAddMemberOpen}
        />
      </Modal>
    </div>
  );
});
