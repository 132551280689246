import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | 'search'
  | 'avatarText'
  | 'flexCenter'
  | 'avatarContainer'
  | 'avatar';

export const useGroupAvatarStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    composes: '$flexCenter',
    border: '1.5px solid #0071CE',
    width: 44,
    height: 44,
    borderRadius: 100,
  },
  avatarContainer: {
    composes: '$flexCenter',
    width: 46,
    height: 46,
    borderRadius: 100,
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.11)',
    border: '1px solid #FFFFFF',
    background: '#F5FBFF',
    padding: 0,
  },
  avatarText: {
    fontSize: '1rem',
    color: '#0071CE',
  },
  search: {
    width: '2.375rem',
    height: '2.375rem',
  },
}));
