import { FC, useContext, ReactNode, useRef } from 'react';
import { Form, Field } from 'react-final-form';
import { FormApi } from 'final-form';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { AddAttachmentIcon } from '../icons/add-attachment';
import { SendIcon } from '../icons/send';
import { SendActiveIcon } from '../icons/send-active';
import { Input } from '../input';
import { UploadFile } from '../uploadFile';
import { IFormMessageInput } from './messageInput.model';
import { useMessageInputStyles } from './messageInput.styles';

export const MessageInput: FC = observer(() => {
  const fileInput = useRef<HTMLInputElement>(null);
  const appStore = useContext(appStoreContext);
  const classes = useMessageInputStyles();

  const submit = (values: IFormMessageInput, form: FormApi<IFormMessageInput>): void => {
    if (!appStore.file.loading) {
      appStore.conversation.sendMessage(values.message);
      form.reset();
    }
  };

  const chooseFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target?.files) {
      appStore.file.uploadFile(e.target.files[0]);
    }
  };

  const triggerInput = (): void => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  return (
    <Form
      onSubmit={submit}
      render={({ handleSubmit, form, values }): ReactNode => (
        <form className={classes.messageInputContainer} onSubmit={(e): void => {
          e.preventDefault();
          handleSubmit()?.then(() => form.reset());
        }}>
          <UploadFile />
          <div className={classes.inputForm}>
            <Field
              name="message"
              component={Input}
              placeholder="Ваше сообщение"
            />
          </div>
          <button disabled={!values.message && !values.file} className={classes.sendButton} type="submit">
            {
              (values.message || values.file) ? (
                <SendActiveIcon />
              ) : (
                <SendIcon />
              )
            }
          </button>
          {/* <button onClick={triggerInput} className={classes.addAttachmentButton} type="button">
            <AddAttachmentIcon />
          </button> */}
          {/* <Field name="file">
            {
              (props): ReactNode => (
                <input
                  ref={fileInput}
                  style={{ display: 'none' }}
                  type="file"
                  {...props.input}
                  onChange={(e): void => {
                    chooseFile(e);
                    props.input.onChange(e);
                  }}
                />
              )
            }
          </Field> */}
        </form>
      )}
    />
  );
});
