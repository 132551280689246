import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | 'messenger'
  | 'loading'
  | 'container';

export const useMessengerStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  messenger: {
    display: 'grid',
    gridTemplateColumns: '348px 4fr',
    gridTemplateRows: '1fr',
    position: 'absolute',
    top: '3.75rem',
    left: 0,
    height: 'calc(100% - 3.75rem)',
    width: '100%',
  },
  container: {
    borderRadius: 8,
    backgroundColor: '#fff',
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
  },
  loading: {
    pointerEvents: 'none',
    cursor: 'wait',
  },
  '@media (max-width: 1280px)': {
    container: {
      borderRadius: 8,
      backgroundColor: '#fff',
      position: 'absolute',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
    },
  },
  '@media (max-width: 768px)': {
    messenger: {
      gridTemplateColumns: 'none',
    },
    container: {
      borderRadius: 8,
      backgroundColor: '#fff',
      position: 'absolute',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
    },
  },
  '@media (max-width: 756px)': {
    container: {
      borderRadius: 8,
      backgroundColor: '#fff',
      position: 'absolute',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
    },
  },
  '@media (max-width: 375px)': {
    container: {
      borderRadius: 8,
      backgroundColor: '#fff',
      position: 'absolute',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
    },
  },
}));
