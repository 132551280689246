import { FC, PropsWithChildren } from 'react';
import { useTheme } from 'react-jss';
import { ITheme } from '../../theme';
import { useLayoutStyles } from './layout.styles';

export const Layout: FC<PropsWithChildren> = (props) => {
  const theme: ITheme | undefined = useTheme();
  const classes = useLayoutStyles({ theme });

  return (
    <>
      <div className={classes.layout}>
        {props.children}
      </div>
    </>
  );
};
