import { FC } from 'react';
import { useTheme } from 'react-jss';
import { ITheme } from '../../theme';
import { SearchIcon } from '../icons/search';
import { ISearchInput } from './searchInput.model';
import { useSearchInputStyles } from './searchInput.styles';

export const SearchInput: FC<ISearchInput> = (props) => {
  const theme: ITheme | undefined = useTheme();
  const classes = useSearchInputStyles({ theme });
  return (
    <div className={classes.searchContainer}>
      <input
        {...props}
        className={classes.searchInput}
      />
      {
        !props.noIcon && (
          <div className={classes.icon}>
            <SearchIcon />
          </div>
        )
      }
    </div>
  );
};
