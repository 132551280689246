export enum EnvKeys {
  baseURL = 'REACT_APP_MESSENGER_BACK_API',
  baseTeamFlameURL = 'REACT_APP_TEAMFLAME_BACK_API',
  localToken = 'REACT_APP_LOCAL_TOKEN',
  websocketURL = 'REACT_APP_MESSENGER_WEBSOCKET_API',
  websocketPath = 'REACT_APP_MESSENGER_WEBSOCKET_PATH',
  maxLengthInput = 'REACT_APP_MESSENGER_MAX_LENGTH_INPUT',
}

export interface IConfigStore {
  baseURL: string;
  localToken?: string | undefined;
  websocketURL: string;
  websocketPath?: string;
  maxLengthInput: number
  baseTeamFlameURL: string;
}
