import { FC } from 'react';
import NiceModal, { antdModal, NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import cn from 'classnames/bind';
import { Modal } from '../modal/modal.view';
import { IConfirmModalParams } from './confirmModal.model';
import { useConfirmModalStyles } from './confirmModal.styles';

export const ConfirmModal: FC<IConfirmModalParams & NiceModalHocProps> = NiceModal.create((props) => {
  const modal = useModal();
  const classes = useConfirmModalStyles();

  const onClose = (): void => {
    modal.reject();
    modal.hide();
  };

  const isPrimaryType = props.confirmType === 'primary';

  const cx = cn.bind(classes);

  const containerClass = cx({
    promptContainerPrimary: isPrimaryType,
    promptContainer: isPrimaryType,
  });
  const iconContainerClass = cx({
    iconContainerPrimary: isPrimaryType,
    iconContainer: isPrimaryType,
  });
  const buttonClass = cx({
    primaryButton: isPrimaryType,
    dangerButton: !isPrimaryType,
  });

  return (
    <Modal
      {...antdModal(modal)}
      modalData={modal}
      title={props.title}
      isOpen={modal.visible}
      setIsOpen={onClose}
    >
      <div className={containerClass}>
        <div className={iconContainerClass}>
          {props.icon}
        </div>
        <p className={classes.title}>{props.confirmTitle}</p>
      </div>
      <p className={classes.description}>{props.confirmDescription}</p>
      <div className={classes.buttonContainer}>
        <div onClick={(): void => {
          modal.resolve();
          modal.hide();
        }} className={buttonClass}>{props.buttonTitle}</div>
        <div onClick={onClose} className={classes.cancelButton}>Отменить</div>
      </div>
    </Modal>
  );
});
