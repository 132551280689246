import React, { FC, useContext } from 'react';
import { observer } from 'mobx-react';
import { humanFileSize } from '../../../shared/human-file-size';
import { appStoreContext } from '../../../stores/context.store';
import { CloseIcon } from '../icons/close';
import { FileIcon } from '../icons/FileIcon';
import { useUploadFileStyles } from './uploadFile.styles';

export const UploadFile: FC = observer(() => {
  const appStore = useContext(appStoreContext);
  const classes = useUploadFileStyles();

  if (!appStore.file.fileName) {
    return <div />;
  }

  const fileSize = appStore.file.fileSize && humanFileSize(appStore.file.fileSize);

  return (
    <div className={classes.container}>
      <div className={classes.flex}>
        <div className={classes.leftSide}>
          {
            appStore.file.loading ? (
              <div className={classes.progressBar}>
                <div className={classes.progress} style={{ width: `${appStore.file.uploadProgress}%` }} />
              </div>
            ) : (
              <div className={classes.fileIcon}>
                <FileIcon />
              </div>
            )
          }
        </div>
        <div>
          <p className={classes.fileName}>{appStore.file.fileName}</p>
          {
            appStore.file.fileSize && (
              <p className={classes.fileSize}>{fileSize}</p>
            )
          }
        </div>
      </div>
      {
        appStore.file.fileId && (
          <span className={classes.close} onClick={(): void => appStore.file.clearData()}>
            <CloseIcon />
          </span>
        )
      }
    </div>
  );
});
