import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { Notifications } from './views/components/notifications';
// import './index.scss';

const rootElement = document.getElementById('root');
if (rootElement !== null) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Notifications>
          <App />
        </Notifications>
      </BrowserRouter>
    </React.StrictMode>,
  );
}
