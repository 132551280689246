import { makeAutoObservable, runInAction } from 'mobx';
import { IFileResponse, IFileStore } from '../models/stores/files.store';
import { IRootStore } from '../models/stores/root.store';

export class FileStore implements IFileStore {

  public readonly rootStore: IRootStore;

  public loading: boolean = false;

  public file: File | null = null;
  public fileId: string | null = null;
  public fileName: string | null = null;
  public fileSize: number | null = null;
  public uploadProgress: number | null = null;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public async uploadFile(file: File): Promise<void> {
    try {
      this.loading = true;
      const { name } = file;
      this.fileName = name;
      const form = new FormData();
      form.append('file', file);
      const data = await this.rootStore.requests.json<IFileResponse>({
        baseURL: this.rootStore.config.baseURL,
        headers: {
          authorization: this.rootStore.user.token || '',
        },
        method: 'POST',
        data: form,
        route: `/api/v1/messages/upload-doc?fileName=${name}`,
        onUploadProgress: (progressEvent: ProgressEvent) => {
          let percentComplete = progressEvent.loaded / progressEvent.total;
          percentComplete *= 100;
          console.log(percentComplete);
          runInAction(() => {
            this.uploadProgress = percentComplete;
          });
        },
      });
      this.fileId = data.document._id;
      this.fileSize = file.size;
      this.uploadProgress = 0;
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }

    this.loading = false;
  }

  public clearData(): void {
    this.file = null;
    this.fileId = null;
    this.fileName = null;
    this.fileSize = null;
    this.uploadProgress = null;
  }

}
