import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | 'icon'
  | 'menuIcon'
  | 'addIcon'
  | 'iconsContainer'
  | 'messengerHeader'
  | 'userInfo'
  | 'userInfoContainer'
  | 'userInfoAvatar'
  | 'userInfoName'
  | 'divider'
  | 'spaceContainer'
  | 'isGroup'
  | 'goBackBlock'
  | 'goBackInnerBlock'
  | 'goBackText'
  | 'goBackIcon'
  | 'wrapper'
  | 'messengerTitle'
  | 'settingsIcon'
  | 'menu'
  | 'menuItem'
  | 'spaceInnerContainer'
  | 'messengerUsers';

export const useMessengerHeaderStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  messengerHeader: {
    backgroundColor: '#fff',
    display: 'grid',
    gridTemplateColumns: '348px 4fr',
    gridTemplateRows: '1fr',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    position: 'relative',
    // boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.04)',
    height: '60px',
  },
  messengerTitle: {
    width: '170px',
    height: '30px',
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '30px',
    textAlign: 'right',
    color: '#21212A',
  },
  messengerUsers: {
    width: '170px',
    height: '30px',
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '24px',
    color: '#3B4168',
  },
  spaceContainer: {
    position: 'relative',
    borderRight: '1px solid #E4E7F2',
  },
  spaceInnerContainer: {
    // display: 'flex',
    // justifyContent: 'space-between',
    // alignItems: 'center',
  },
  divider: {
    position: 'absolute',
    right: 0,
    top: '0.438rem',
    height: '2.813rem',
    width: 1,
    backgroundColor: '#E4E7F2',
  },
  userInfoContainer: {
    display: 'flex',
  },
  userInfo: {
    display: 'flex',
    marginLeft: '1.5rem',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'space-between',
    width: '100%',
  },
  userInfoAvatar: {
    width: '2.875rem',
    height: '2.875rem',
    borderRadius: 50,
  },
  userInfoName: {
    marginLeft: '0.75rem',
    fontWeight: 500,
    fontSize: '1.125rem',
  },
  icon: {
    cursor: 'pointer',
  },
  menuIcon: {
    composes: '$icon',
    marginLeft: '0.875rem',
  },
  addIcon: {
    composes: '$icon',
    width: '36px',
    height: '36px',
    borderRadius: 50,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colorSecondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      background: 'rgba(0, 113, 206, 0.07)',
      transition: 'background 0.3s ease',
    },
  },
  settingsIcon: {
    composes: '$icon',
    width: '36px',
    height: '36px',
    borderRadius: 50,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colorSecondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      background: 'rgba(0, 113, 206, 0.07)',
      transition: 'background 0.3s ease',
    },
    '& rect': {
      '&:hover': {
        fill: 'rgba(0, 113, 206, 0.07)',
        transition: 'fill 0.3s ease',
      },
    },
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '88px',
    justifyContent: 'space-around',
    gap: '12px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#E4E7F2',
  },
  goBackBlock: {
    background: '#F5F6F8',
    height: '60px',
    position: 'relative',
    display: 'none',
  },
  goBackInnerBlock: {
    display: 'flex',
    position: 'absolute',
    justifyContent: 'space-around',
    alignItems: 'center',
    background: '#F5F6F8',
    width: '242px',
    height: '22px',
    Gap: '5%',
    marginLeft: '40px',
  },
  goBackText: {
    color: '#909EBB',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
    font: 'Manrope',
    marginRight: '-25px',
  },
  menu: {
    width: '132px',
    border: '1px solid #E4E7F2',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    marginLeft: '-150px',
    marginTop: '-5px',
    display: 'flex',
    zIndex: '100',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
  },
  menuItem: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    color: '#3B4168',
    marginLeft: '-24px',
    marginTop: '8px',
    cursor: 'pointer',
    listStyleType: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '5px',
    '&:hover': {
      color: '#0071CE',
    },
    '&:last-child': {
      marginBottom: '8px',
    },
  },
  goBackIcon: {
    position: 'absolute',
    left: '-1.33%',
    right: '16.67%',
    top: '11.59%',
    bottom: '17.59%',
    marginLeft: '-5px !important',
  },
  isGroup: {
    display: 'none !important',
  },
  '@media (max-width: 768px)': {
    messageHeader: {
      width: '89.3%',
    },
    messengerHeader: {
      boxShadow: 'none',
      gridTemplateColumns: 'none',
    },
    divider: {
      display: 'none',
    },
    iconsContainer: {
      justifyContent: 'flex-end',
    },
    userInfoContainer: {
      width: '99%',
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.07)',
    },
    userInfo: {
      marginLeft: '40px',
    },
    // spaceContainer: {
    //   width: '99%',
    //   height: '70px',
    //   boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.04)',
    //   borderBottom: 1,
    //   borderBottomStyle: 'solid',
    //   borderBottomColor: '#E4E7F2',
    // },
    spaceInnerContainer: {
      width: '89%',
      padding: 0,
      marginLeft: '2.8%',
      marginRight: '2.2%',
    },
    goBackBlock: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
  '@media (max-width: 375px)': {
    messageHeader: {
      width: '375px',
    },
    goBackBlock: {
      width: 375,
      height: '46px',
    },
    goBackInnerBlock: {
      marginLeft: '19px',
    },
    addIcon: {
      display: 'none',
    },
    iconsContainer: {
      justifyContent: 'flex-end',
    },
    userInfoContainer: {
      width: '375px',
    },
    spaceContainer: {
      width: '375px',
      boxShadow: 'none',
      borderRight: '1px solid #000',
    },
    spaceInnerContainer: {
      width: '336px',
      padding: 0,
      marginLeft: -8,
      marginRight: 62,
      borderRight: '1px solid #000',
      borderBottom: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#E4E7F2',
    },
  },
}));
