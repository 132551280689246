import { FC, useContext, ChangeEvent, useState, useEffect, useRef } from 'react';
import { useTheme } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { ITheme } from '../../theme';
import { Avatar } from '../avatar';
import { NotFoundIcon } from '../icons/not-found';
import { SearchInput } from '../searchInput';
import { useSearchStyles } from './search.styles';

export const Search: FC = observer(() => {
  const navigate = useNavigate();
  const searchRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState('');
  const appStore = useContext(appStoreContext);
  const theme: ITheme = useTheme();
  const classes = useSearchStyles({ theme });
  const { isDropdownOpen, loading, users } = appStore.search;

  const searchUser = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(e.currentTarget.value);
    appStore.search.search(e.currentTarget.value);
  };

  const chooseUser = async(id: string): Promise<void> => {
    appStore.search.setDropdownOpen(false);
    appStore.search.clear();
    setValue('');
    await appStore.conversation.createChat([id]);
    navigate(`messenger/${appStore.conversation.chatId}`);
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent): void => {
      if (searchRef && searchRef.current) {
        const ref = searchRef.current;
        if (e.target instanceof Node) {
          if (!ref.contains(e.target) && document.activeElement?.id !== 'searchInput') {
            appStore.search.setDropdownOpen(false);
          }
        }
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  const notFoundContainerClassName = cn(classes.dropdownContainer, classes.notFoundContainer);

  return (
    <div ref={searchRef} className={classes.searchContainer}>
      <SearchInput
        placeholder="Искать по чатам"
        onChange={searchUser}
        value={value}
        id="searchInput"
      />
      {/* {
        (isDropdownOpen && users) && (
          <div className={classes.dropdownContainer}>
            {
              users.map((item) => <span onClick={(): Promise<void> => chooseUser(item.id)} key={item.id} className={classes.userContainer}>
                <Avatar
                  search
                  src={item.avatar}
                />
                <p className={classes.userName}>{item.name}</p>
                <div className={classes.divider} />
              </span>)}
          </div>
        )
      }
      {
        (isDropdownOpen && !users && value.length > 3 && !loading) && (
          <div className={notFoundContainerClassName}>
            <NotFoundIcon />
            <p className={classes.notFoundText}>По данному запросу ничего не найдено</p>
          </div>
        )
      } */}
    </div>
  );
});
