import { Outlet, RouteObject } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { BrowserRoute } from '../../routes/browser.routes';
// Components
import { Layout } from '../components/layout/layout.view';
// Content
import { Auth } from '../content/auth';
import { Conversation } from '../content/conversation';
import { Main } from '../content/main';
import { NotFound } from '../content/not-found';
import { StartPage } from '../content/start-page';
import { AppThemeProvider } from '../theme';

export const appRoutesDefinition: RouteObject[] = [{
  path: '/',
  element: (
    <AppThemeProvider>
      <Layout>
        <Auth>
          <NiceModal.Provider>
            <Main>
              <Outlet />
            </Main>
          </NiceModal.Provider>
        </Auth>
      </Layout>
    </AppThemeProvider>
  ),
  children: [{
    path: '*',
    element: <NotFound />,
  }, {
    path: '/',
    element: <StartPage />,
  }, {
    path: BrowserRoute.messengerConversation,
    element: <Conversation />,
  }],
}];
