import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../../stores/context.store';
import { Avatar } from '../../avatar';
import { GoBackIcon } from '../../icons/go-back';
import { useMessengerHeaderStyles } from '../messengerHeader.styles';

export const ChatHeader = observer(() => {
  const appStore = useContext(appStoreContext);
  const navigate = useNavigate();
  const { user, isGroup, users } = appStore.conversation;
  const classes = useMessengerHeaderStyles();
  const openEditGroup = (): void => {
    if (isGroup) {
      appStore.conversation.openEditGroup(true);
    }
  };
  const handleBack = (): void => {
    appStore.conversation.setConversation(null);
    navigate('/');
  };
  return (
    <div className={classes.wrapper}>
      <div className={classes.goBackBlock} onClick={handleBack}>
        <div className={classes.goBackInnerBlock}>
          <GoBackIcon className={classes.goBackIcon} />
          <div className={classes.goBackText}>
            Вернуться к пользователям
          </div>
        </div>
      </div>
      <a onClick={openEditGroup} className={classes.userInfoContainer}>
        <div className={classes.userInfo}>
          <p className={classes.userInfoName}>{user?.name}</p>
          <div className={classes.userInfoAvatar}>
            <Avatar
              isGroup={user?.isGroup}
              src={user?.avatar}
              membersCount={(users?.length || 0) + 1}
            />
          </div>
        </div>
      </a>
    </div>
  );
});
