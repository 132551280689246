import { FC } from 'react';
import cn from 'classnames/bind';
import { GroupAvatar } from '../groupAvatar';
import { NoAvatar } from '../noAvatar';
import { IAvatar } from './avatar.model';
import { useAvatarStyles } from './avatar.styles';

export const Avatar: FC<IAvatar> = ({ isGroup, src, membersCount, search }) => {
  const classes = useAvatarStyles();
  const cx = cn.bind(classes);
  const avatarClassName = cx(classes.avatar, { search });

  if (isGroup && membersCount) {
    return (
      <GroupAvatar search={search} count={membersCount} />
    );
  }
  if (src) {
    return (
      <img className={avatarClassName} alt="avatar" src={src} />
    );
  }
  return (
    <NoAvatar search={search} />
  );
};
