import { makeAutoObservable, runInAction } from 'mobx';
import { IFindByKey, IUserData } from '../models/requests/chat.models';
import { IRootStore } from '../models/stores/root.store';
import { IUserStore } from '../models/stores/user.store';
import { getErrorMessage } from '../shared/error-message';

export class UserStore implements IUserStore {

  public readonly rootStore: IRootStore;

  public token?: string = undefined;
  public loading: boolean = true;
  public error?: string = undefined;
  public user?: IUserData | undefined;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }


  public getToken(): void {
    try {
      runInAction(() => {
        this.token = undefined;
        this.loading = true;
        this.error = undefined;
      });

      const localToken = this.rootStore.config.localToken;
      if (typeof localToken === 'string' && localToken.length > 0) {
        runInAction(() => {
          this.token = localToken;
        });
        runInAction(() => {
          this.loading = false;
        });
        return;
      }

      const urlParams = new URLSearchParams(window.location.search);
      const unioneToken = urlParams.get('token');

      if (typeof unioneToken === 'string' && unioneToken !== 'null' && unioneToken.length > 0) {
        runInAction(() => {
          this.token = unioneToken;
        });
        runInAction(() => {
          this.loading = false;
        });
        return;
      }
      runInAction(() => {
        this.loading = false;
      });
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  }

  public async getUserInfo(): Promise<void> {
    try {
      const user = await this.rootStore.requests.json<IFindByKey>({
        baseURL: this.rootStore.config.baseURL,
        headers: {
          authorization: `Bearer ${this.token}` || '',
        },
        method: 'GET',
        route: '/auth/me',
      });

      this.user = user;
    }
    catch (error) {
      console.log('Backend error: ', error);
    }
  }

}
