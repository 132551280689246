import { FC } from 'react';
import { Link } from 'react-router-dom';
import { BrowserRoute } from '../../../routes/browser.routes';
import { WarningIcon } from '../../components/icons/warning';

export const NotFound: FC = () => {
  const stateBlockCN = 'w-full flex items-top justify-center py-16';

  return (
    <div className={stateBlockCN}>
      <div className="flex flex-col items-center gap-y-2">
        <div className="flex flex-col items-center gap-y-2 text-status-warning">
          <WarningIcon width={64} height={64} />
          <div className="text-lg font-semibold">
            Страница не найдена
          </div>
        </div>
        <Link className="text-sm text-primary-darkBlue" to={BrowserRoute.messenger} >
          Перейти на страницу мессенджера
        </Link>
      </div>
    </div>
  );
};
