import { EnvKeys, IConfigStore } from '../models/stores/config.models';

export class ConfigStore implements IConfigStore {

  public readonly websocketURL: string;
  public readonly localToken: string | undefined;
  public readonly maxLengthInput: number;
  public readonly baseURL: string;
  public readonly baseTeamFlameURL: string;
  public readonly websocketPath?: string | undefined;

  constructor() {
    this.websocketURL = this.getRequired(EnvKeys.websocketURL) || 'https://chat-api.test-team-flame.ru';
    this.localToken = this.getValue(EnvKeys.localToken);
    this.maxLengthInput = this.getInteger(EnvKeys.maxLengthInput) || 5000;
    this.baseURL = this.getRequired(EnvKeys.baseURL) || 'https://chat-api.test-team-flame.ru';
    this.websocketPath = this.getValue(EnvKeys.websocketPath);
    this.baseTeamFlameURL = this.getRequired(EnvKeys.baseTeamFlameURL) || 'https://api.test-team-flame.ru';
  }

  private getValue(key: EnvKeys): string | undefined {
    const value = process.env[key];
    return value;
  }

  private getRequired(key: EnvKeys): string {
    const value = this.getValue(key);
    if (typeof value !== 'string' || value.length === 0) {
      throw new Error(`Cannot find "${key}" parameter from environment`);
    }
    return value;
  }

  private getInteger(key: EnvKeys): number {
    const value = parseInt(this.getRequired(key));
    if (Number.isNaN(value)) {
      throw new Error(`${key} is not valid number`);
    }
    return value;
  }

}
