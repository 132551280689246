import { FC, useCallback, useContext, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { EditGroup } from '../../components/editGroup';
import { StartMessageIcon } from '../../components/icons/start-message';
import { MessageInput } from '../../components/messageInput';
import { useConversationStyles } from './conversation.styles';
import { Messages } from './messages/Messages';

export const Conversation: FC = observer(() => {
  const anchor = useRef<HTMLDivElement>(null);
  const appStore = useContext(appStoreContext);
  const { conversationId } = useParams();
  const navigate = useNavigate();
  const classes = useConversationStyles();
  const { chatId, messages } = appStore.conversation;

  useEffect(() => {
    if (conversationId && conversationId !== chatId) {
      appStore.conversation.setConversation(conversationId);
    }
  }, [conversationId]);

  const handleUserKeyPress = useCallback((event: globalThis.KeyboardEvent) => {
    const { key } = event;
    if (key === 'Escape') {
      navigate('/', { replace: true });
      appStore.conversation.setConversation(null);
    }
  }, [navigate]);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  useEffect(() => {
    if (anchor.current) {
      (anchor.current).scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [chatId, messages.length]);

  return (
    <div className={classes.conversationContainer}>
      <div className={classes.messagesContainer}>
        {
          messages.length ? (
            messages.map((message, index) => (
              <Messages message={message} index={index} />
            ))
          ) : (
            <div className={classes.startPage}>
              <div className={classes.container}>
                <StartMessageIcon />
                <p className={classes.title}>{ 'Напишите первым!' }</p>
              </div>
            </div>
          )
        }
        <div ref={anchor} className={classes.anchor} />
      </div>
      <MessageInput />
      <EditGroup />
    </div>
  );
});
