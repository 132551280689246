import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | 'flex'
  | 'close'
  | 'leftSide'
  | 'fileName'
  | 'fileSize'
  | 'fileIcon'
  | 'progress'
  | 'progressBar'
  | 'container';

export const useUploadFileStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    background: theme.colorWhiteBackground,
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: 16,
    paddingRight: 16,
    position: 'absolute',
    bottom: 60,
    left: 0,
    right: 0,
    composes: '$flex',
    justifyContent: 'space-between',
  },
  fileName: {
    fontFamily: theme.fontIBM,
    color: theme.colorFont,
    fontSize: '1rem',
    lineHeight: 0,
  },
  progressBar: {
    width: 150,
    height: 6,
    background: '#E4E7F2',
    borderRadius: 14,
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    background: theme.colorPrimary,
    borderRadius: 14,
    left: 0,
    top: 0,
    height: 6,
  },
  fileSize: {
    fontFamily: theme.fontIBM,
    fontSize: '0.875rem',
    color: '#6F7C98',
    lineHeight: 0,
  },
  fileIcon: {
    width: 36,
    height: 36,
    background: 'rgba(0, 113, 206, 0.07)',
    borderRadius: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftSide: {
    marginRight: 8,
  },
  close: {
    width: 24,
    height: 24,
    composes: '$flex',
    justifyContent: 'center',
    cursor: 'pointer',
    background: 'rgba(144, 158, 187, 0.1)',
    borderRadius: 50,
  },
}));
