import { FC, useContext } from 'react';
import cn from 'classnames/bind';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { Avatar } from '../avatar';
import { FileIcon } from '../icons/FileIcon';
import { IMessageComponent } from './message.model';
import { useMessageStyles } from './message.styles';

export const Message: FC<IMessageComponent> = observer(({ username, from, text, timestamp, messageId, avatar, attachment }) => {
  const appStore = useContext(appStoreContext);
  const classes = useMessageStyles();

  // const onChooseMessage = (): void => {
  //   appStore.conversation.onChooseMessage(messageId);
  // };

  const isMyMessage = from === appStore.user.user?.id;

  const cx = cn.bind(classes);

  const choosenMessageClass = cx({
    choosenMessage: appStore.conversation.choosenMessage === messageId,
  });

  const chatMsgClass = cx(classes.chatMsg);

  const chatDateClass = cx({
    chatMsgDateOwner: isMyMessage,
    chatMsgDate: !isMyMessage,
  });
  const chatContentClass = cx({
    chatMsgContentOwner: isMyMessage,
    chatMsgContent: !isMyMessage,
  });
  const chatTextClass = cx({
    chatMsgTextOwner: isMyMessage,
    chatMsgText: !isMyMessage,
  });
  const chatUsernameClass = cx({
    usernameOwner: isMyMessage,
    username: !isMyMessage,
  });

  const fileIconClass = cx(classes.fileIcon, {
    fileIconOwner: isMyMessage,
  });

  const downloadClass = cx(classes.download, {
    downloadOwner: isMyMessage,
  });

  const formatedDate = format(new Date(timestamp), 'HH:mm');

  const formatedAttachment = attachment?.attachment_name.split('-')[5];

  return (
    <div>
      <div className={chatMsgClass}>
        <div className={classes.chatMsgProfile}>
          <Avatar
            src={avatar}
          />
        </div>
        <div className={classes.chatMsgContent}>
          <div className={classes.username}>
            <div>{username}</div>
            <div className={classes.chatMsgDate}>{formatedDate}</div>
          </div>
          <div className={classes.chatMsgText}>
            {text}
            {
              (attachment && attachment.link) && (
                <>
                  {text && <br />}
                  <a className={downloadClass} href={attachment.link} target="_blank" rel="noreferrer">
                    <div className={fileIconClass}>
                      <FileIcon fill={isMyMessage ? '#fff' : '#0071CE'} />
                    </div>
                    <p>
                      {formatedAttachment}
                    </p>
                  </a>
                </>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
});
