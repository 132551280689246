import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | 'search'
  | 'avatar';

export const useAvatarStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  avatar: {
    width: 44,
    height: 44,
    borderRadius: 100,
    border: 2,
    borderStyle: 'solid',
    borderColor: theme.colorWhite,
  },
  search: {
    width: '2.375rem',
    height: '2.375rem',
  },
}));
