import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | 'input';

export const useInputStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  input: {
    width: '100%',
    paddingLeft: '1rem',
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    background: 'none',
    border: 'none',
    color: '#12152A',
    fontSize: '1rem',
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
    '&::placeholder': {
      color: '#909EBB',
    },
  },
}));
