import { FC, Fragment, PropsWithChildren, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { NotificationAuth } from '../../components/NotificationAuth/NotificationAuth';

export const Auth: FC<PropsWithChildren> = observer((props) => {
  const appStore = useContext(appStoreContext);

  useEffect(() => {
    appStore.user.getToken();
  }, []);

  useEffect(() => {
    if (appStore.user.token) {
      appStore.user.getUserInfo();
      appStore.socket.connectSocket();
    }
  }, [appStore.user.token]);

  if (appStore.user.loading) {
    return (
      <div />
    );
  }

  if (typeof appStore.user.token !== 'string') {
    return (
      <Fragment>
        {props.children}
        <NotificationAuth />
      </Fragment>
    );
  }

  return (
    <Fragment>
      {props.children}
    </Fragment>
  );
});
