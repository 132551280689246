import { FC, useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { AddMember } from '../add-member/addMember.view';
import { ChatHeader } from './chatHeader/ChatHeader';
import { ChatListHeader } from './chatListHeader/ChatListHeader';
import { useMessengerHeaderStyles } from './messengerHeader.styles';
import 'rc-dropdown/assets/index.css';

export const MessengerHeader: FC = observer(() => {
  const [isOpenCreateGroup, setIsOpenCreateGroup] = useState(false);
  const appStore = useContext(appStoreContext);
  const chatId = appStore.conversation.chatId;
  const classes = useMessengerHeaderStyles();

  return (
    <div className={classes.messengerHeader}>
      <ChatListHeader setIsOpenCreateGroup={setIsOpenCreateGroup} />
      {
        chatId !== null && (
          <ChatHeader />
        )
      }
      <AddMember
        isCreate
        isOpen={isOpenCreateGroup}
        setIsOpen={setIsOpenCreateGroup}
      />
    </div>
  );
});
