import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | 'searchContainer'
  | 'icon'
  | 'searchInput';

export const useSearchInputStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  searchContainer: {
    width: '100%',
    height: '2.5rem',
    // margin: '1.25rem',
    display: 'grid',
    gridTemplateColumns: '8fr 1fr',
    gridTemplateRows: '1fr',
    alignItems: 'center',
    position: 'relative',
    zIndex: 1,
    borderRadius: 6,
    background: '#F5F5FA',
  },
  searchInput: {
    width: '90%',
    paddingLeft: '1rem',
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    background: 'none',
    border: 'none',
    color: '#12152A',
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
    '&::placeholder': {
      color: '#909EBB',
    },
  },
  icon: {
    textAlign: 'right',
    marginRight: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
}));
