import { ITheme } from './theme.models';

export const AppTheme: ITheme = {
  fontIBM: 'Manrope',

  colorGreyText: '#909EBB',
  colorWhiteBackground: '#F5F6F8',
  colorWhite: '#ffffff',
  colorBlack: '#12152A',
  colorPrimary: '#0071ce',
  colorFont: '#3B4168',
  colorError: '#eb5757',
  colorWarning: '#f7b055',
  colorSuccess: '#73d071',
  colorVisited: '#7d6fe1',

  colorDarkBlue: '#0071ce',

  colorLightBlue: '#73AAFF',

  colorSky50: '#f0f9ff',

  colorGray100: '#f3f4f6',
  colorGray300: '#d1d5db',

  colorSecondary: '#E4E7F2',

  fontSize: '1.125rem',
  fontSizeBig: '2rem',
  fontSizeBase: '1rem',
  fontSizeSm: '0.875rem',

  lineHeight: '1.75rem',
  lineHeightBase: '1.5rem',
  lineHeightSm: '1.25rem',
};
