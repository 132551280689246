import { FC, useContext } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { useInputStyles } from './input.styles';

type Props = FieldRenderProps<string>;

export const Input: FC<Props> = observer(({ input, meta, ...rest }: Props) => {
  const appStore = useContext(appStoreContext);
  const classes = useInputStyles();

  return (
    <input autoFocus className={classes.input} {...input} {...rest} maxLength={appStore.config.maxLengthInput} />
  );
});
