import { FC } from 'react';
import cn from 'classnames/bind';
import { IGroupAvatarProps } from './groupAvatar.model';
import { useGroupAvatarStyles } from './groupAvatar.styles';

export const GroupAvatar: FC<IGroupAvatarProps> = ({ count, search }) => {
  const classes = useGroupAvatarStyles();
  const cx = cn.bind(classes);
  const containerClassName = cx(classes.avatarContainer, {
    search,
  });
  const avatarClassName = cx(classes.avatar, {
    search,
  });

  return (
    <div className={containerClassName}>
      <div className={avatarClassName}>
        <p className={classes.avatarText}>{count}</p>
      </div>
    </div>
  );
};
