import { IFileStore } from '../models/stores/files.store';
import { RequestsService } from '../models/stores/requests.service';
import { IRootStore } from '../models/stores/root.store';
import { ISocketStore } from '../models/stores/socket.store';
import { ChatListStore } from './chatList.store';
import { ConfigStore } from './config.store';
import { ConversationStore } from './conversation.store';
import { FileStore } from './files.store';
import { SearchStore } from './search.store';
import { SocketStore } from './socket.store';
import { UserStore } from './user.auth.store';

export class RootStore implements IRootStore {

  public readonly config: ConfigStore;
  public readonly requests: RequestsService;
  public readonly user: UserStore;
  public readonly chatList: ChatListStore;
  public readonly search: SearchStore;
  public conversation: ConversationStore;
  public socket: ISocketStore;
  public file: IFileStore;

  public get today(): Date {
    return new Date();
  }

  constructor() {
    this.config = new ConfigStore();
    this.requests = new RequestsService(this);
    this.user = new UserStore(this);
    this.chatList = new ChatListStore(this);
    this.requests = new RequestsService(this);
    this.search = new SearchStore(this);
    this.conversation = new ConversationStore(this);
    this.socket = new SocketStore(this);
    this.file = new FileStore(this);
  }

}
