import { FC } from 'react';
import cn from 'classnames/bind';
import { Avatar } from '../avatar';
import { Checkbox } from '../checkbox';
import { CloseRedIcon } from '../icons/close-red';
import { KeyIcon } from '../icons/key';
import { IUserItemType } from './userItem.model';
import { useUserItemStyles } from './userItem.styles';

export const UserItem: FC<IUserItemType> = ({
  onRemove,
  avatar,
  name,
  isAdmin,
  checkbox,
  removeButton,
  checked,
  onChangeCheckbox,
  clickable,
}) => {
  const classes = useUserItemStyles();
  const cx = cn.bind(classes);

  const listItemClass = cx(classes.listItem, {
    clickable,
  });

  const onClick = (): void => {
    if (onChangeCheckbox && checkbox && clickable) {
      onChangeCheckbox(!checked);
    }
  };

  return (
    <div className={listItemClass} onClick={onClick}>
      <div className={classes.leftSide}>
        <Avatar src={avatar} />
        <p className={classes.title}>{name}</p>
        {
          isAdmin && (
            <div className={classes.isAdmin}>
              <KeyIcon />
              <p className={classes.isAdminTitle}>Администратор чата</p>
            </div>
          )
        }
      </div>
      <div>
        {
          (removeButton && !isAdmin && onRemove) && (
            <span onClick={(): void => onRemove()} className={classes.redButton}>
              <CloseRedIcon />
            </span>
          )
        }
        {
          checkbox && (
            <Checkbox checked={checked} onChange={onChangeCheckbox} />
          )
        }
      </div>
    </div>
  );
};
