import { DefaultEventsMap } from '@socket.io/component-emitter';
import { makeAutoObservable, runInAction } from 'mobx';
import { io, Socket } from 'socket.io-client';
import { IGetChats, IUserData } from '../models/requests/chat.models';
import { IChat } from '../models/stores/chatList.store';
import { IMessage, IConversationStore } from '../models/stores/conversation.store';
import { IRootStore } from '../models/stores/root.store';
import { IUsers } from '../models/stores/search.store';

export class ConversationStore implements IConversationStore {

  public readonly rootStore: IRootStore;

  public loading: boolean = false;

  public messages: IMessage[] = [];
  public chatId: string | null = null;
  public isGroup?: boolean | undefined;
  public user: IUsers | null = null;
  public owner?: IUsers | null | undefined;
  public users: IUsers[] | null = null;
  public editGroupIsOpen: boolean = false;
  public choosenMessage?: string | null | undefined;
  public socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined;

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });

    this.rootStore = rootStore;
  }

  public async setConversation(chatId: string | null): Promise<void> {
    let chatData = this.rootStore.chatList.list.find((item) => item.chatId === chatId);
    if (chatId && !chatData) {
      // await this.rootStore.requests.json({
      //   baseURL: this.rootStore.config.baseURL,
      //   headers: {
      //     authorization: this.rootStore.user.token || '',
      //   },
      //   method: 'GET',
      //   params: {
      //     chatId,
      //   },
      //   route: '/api/v1/chat/join-chat',
      // });
      await this.rootStore.chatList.getChatList();
      chatData = this.rootStore.chatList.list.find((item) => item.chatId === chatId);
    }
    if (chatId && chatData) {
      this.chatId = chatId;
      this.isGroup = chatData.isGroup;

      this.user = {
        name: chatData.name,
        id: chatData.userId || '',
        isGroup: this.isGroup,
        numbersOfUsers: chatData.members.length,
        avatar: chatData.avatar,
      };

      const data = await this.rootStore.requests.json<IMessage[]>({
        baseURL: this.rootStore.config.baseURL,
        headers: {
          authorization: `Bearer ${this.rootStore.user.token}` || '',
        },
        method: 'GET',
        route: `/messages/${chatId}`,
      });

      const userIds = chatData.members.concat(chatData.owner);

      const usersData = (await this.rootStore.requests.json<IUserData[]>({
        baseURL: this.rootStore.config.baseTeamFlameURL,
        headers: {
          authorization: `Bearer ${this.rootStore.user.token}` || '',
          'X-Api-Version': 1,
        },
        method: 'GET',
        route: `users?userIds=${Array.from(userIds).filter((a) => a).join(',')}`,
      }));

      const parsedUserData = usersData.map((item) => ({ ...item, avatar: item.avatar && `${item.avatar}`, name: `${item.name} ${item.lastName}` }));

      this.owner = parsedUserData.find((item) => item.id === chatData?.owner);
      this.users = parsedUserData.filter((item) => item.id !== chatData?.owner);
      this.messages = data.reverse();
    }
    else {
      this.chatId = null;
    }
  }

  public sendMessage(message: string): void {
    // await this.rootStore.requests.json<IMessage[]>({
    //   baseURL: this.rootStore.config.baseURL,
    //   headers: {
    //     authorization: `Bearer ${this.rootStore.user.token}` || '',
    //   },
    //   data: {
    //     chat_id: this.chatId,
    //     message,
    //   },
    //   method: 'POST',
    //   route: '/messages/create-message',
    // });
    if (this.rootStore.socket.socket) {
      this.rootStore.socket.socket.emit('sendMessageToService', {
        chat_id: this.chatId,
        sender_id: this.rootStore.user.user?.id,
        attachment_id: this.rootStore.file.fileId,
        message,
      });
      this.rootStore.file.clearData();
    }
  }

  public clearMessages(): void {
    this.messages = [];
  }

  public async createGroup(name: string, members: string[]): Promise<string | null> {
    const chatData = await this.rootStore.requests.json<IGetChats>({
      baseURL: this.rootStore.config.baseURL,
      headers: {
        authorization: this.rootStore.user.token || '',
      },
      method: 'POST',
      data: { members },
      route: '/api/v1/chat/create-chat',
    });
    this.rootStore.socket.socket?.emit('chatCreatedToService', chatData);
    await this.updateGroup(chatData._id, name, 'null');
    return chatData._id;
  }

  public async updateGroup(chatId: string, name?: string, avatar?: string): Promise<void> {
    if (chatId) {
      await this.rootStore.requests.json({
        baseURL: this.rootStore.config.baseURL,
        headers: {
          authorization: this.rootStore.user.token || '',
        },
        method: 'PUT',
        data: {
          chatId,
          avatar,
          name,
        },
        route: '/api/v1/chat/update-chat',
      });

      if (this.chatId === chatId) {
        this.user = {
          name: name || '',
          id: this.chatId,
          isGroup: this.isGroup,
          numbersOfUsers: this.user?.numbersOfUsers,
          avatar: avatar || 'null',
        };
      }

      await this.rootStore.chatList.getChatList();
      const chatData = this.rootStore.chatList.list.find((item) => item.chatId === chatId);
      if (chatData) {
        this.chatId = chatId;
        this.rootStore.socket.socket?.emit('chatCreatedToService', chatData);
      }
    }
  }

  public openEditGroup(isOpen: boolean): void {
    this.editGroupIsOpen = isOpen;
  }

  public onChooseMessage(messageId: string): void {
    this.choosenMessage = messageId;
  }

  public async createChat(members: string[]): Promise<void> {
    this.loading = true;
    try {
      const chatData = await this.rootStore.requests.json<IGetChats>({
        baseURL: this.rootStore.config.baseURL,
        headers: {
          authorization: this.rootStore.user.token || '',
        },
        method: 'POST',
        data: { members },
        route: '/api/v1/chat/create-chat',
      });
      this.rootStore.chatList.getChatList();
      this.loading = false;
      this.chatId = chatData._id;
      this.rootStore.socket.socket?.emit('chatCreatedToService', chatData);
    }
    catch (error) {
      console.log('Error with create chat');
    }

    this.loading = false;
    this.chatId = null;
  }

  public async removeUser(membersToDelete: string[]): Promise<void> {
    // TODO: use component confirm
    // eslint-disable-next-line no-alert
    const isConfirm = window.confirm('Вы уверены, что хотите удалить пользователя?');
    if (isConfirm) {
      try {
        await this.rootStore.requests.json({
          baseURL: this.rootStore.config.baseURL,
          headers: {
            authorization: this.rootStore.user.token || '',
          },
          method: 'POST',
          data: {
            chatId: this.chatId,
            membersToDelete,
          },
          route: '/api/v1/chat/delete-chat-member',
        });
        await this.setConversation(this.chatId);
        await this.rootStore.chatList.getChatList();
        this.users = this.users?.filter((item) => membersToDelete.indexOf(item.id) === -1) || null;
      }
      catch (error) {
        console.error('Remove user err: ', error);
      }
    }
  }

  public async exitChat(chatId: string): Promise<void> {
    try {
      await this.rootStore.requests.text({
        baseURL: this.rootStore.config.baseURL,
        headers: {
          authorization: this.rootStore.user.token || '',
        },
        method: 'DELETE',
        route: `/api/v1/chat/exit-chat?chatId=${chatId}`,
      });
      this.editGroupIsOpen = false;
      await this.setConversation(null);
      await this.rootStore.chatList.getChatList();
    }
    catch (error) {
      console.error('Exit chat err: ', error);
    }
  }

  public async deleteChat(chatId: string): Promise<void> {
    try {
      await this.rootStore.requests.text({
        baseURL: this.rootStore.config.baseURL,
        headers: {
          authorization: this.rootStore.user.token || '',
        },
        method: 'DELETE',
        route: `/api/v1/chat/delete-chat?chatId=${chatId}`,
      });
      this.editGroupIsOpen = false;
      await this.setConversation(null);
      await this.rootStore.chatList.getChatList();
    }
    catch (error) {
      console.error('Exit chat err: ', error);
    }
  }

}
