import { FC, useState } from 'react';
import { observer } from 'mobx-react';
import Dialog from 'rc-dialog';
import { CloseModalIcon } from '../icons/close-modal';
import { EditIcon } from '../icons/edit';
import { IModalType } from './modal.model';
import { useModalStyles, maskStyle } from './modal.styles';

export const Modal: FC<IModalType> = observer(({ children, title, isOpen, setIsOpen, editableTitle, onSubmitTitle }) => {
  const [isEditTitle, setIsEditTitle] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const classes = useModalStyles();

  const onClose = (): void => {
    setIsOpen(false);
  };

  return (
    <Dialog
      maskStyle={maskStyle}
      className={classes.modal}
      onClose={onClose}
      closable={false}
      visible={isOpen}
      mask
      maskClosable
    >
      <div className={classes.header}>
        <div className={classes.flex}>
          <p contentEditable={isEditTitle} onInput={(e): void => setNewTitle(e.currentTarget.textContent || '')} className={classes.title}>{title}</p>
          {
            (editableTitle && !isEditTitle) && (
              <div onClick={(): void => setIsEditTitle(true)} className={classes.editContainer}>
                <EditIcon />
              </div>
            )
          }
        </div>
        <div className={classes.header}>
          {
            isEditTitle && (
              <span className={classes.saveButton} onClick={():void => {
                setIsEditTitle(false);
                if (onSubmitTitle) {
                  onSubmitTitle(newTitle);
                }
              }}>
                Сохранить
              </span>
            )
          }
          <a className={classes.closeIcon} onClick={onClose}>
            <CloseModalIcon />
          </a>
        </div>
      </div>
      <div className={classes.divider} />
      {children}
    </Dialog>
  );
});
