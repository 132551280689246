import { FC, PropsWithChildren, useContext, useEffect } from 'react';
import cn from 'classnames/bind';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { ChatList } from '../../components/chatsList';
import { MessengerHeader } from '../../components/messengerHeader';
import { useMessengerStyles } from './messenger.styles';

export const Messenger: FC<PropsWithChildren> = observer(({ children }) => {
  const appStore = useContext(appStoreContext);
  const classes = useMessengerStyles();
  const cx = cn.bind(classes);
  useEffect(() => {
    appStore.chatList.getChatList();
  }, [appStore]);

  const messengerClassNames = cx(classes.container, {
    loading: appStore.conversation.loading,
  });

  return (
    <div className={messengerClassNames}>
      <MessengerHeader />
      <div className={classes.messenger}>
        <ChatList
          list={appStore.chatList.list}
        />
        {children}
      </div>
    </div>
  );
});
