import { createUseStyles } from 'react-jss';
import { ITheme } from '../../theme';

type RuleNames =
  | 'button'
  | 'buttonContainer'
  | 'primaryButton'
  | 'cancelButton'
  | 'dangerButton'
  | 'buttonText'
  | 'title'
  | 'description'
  | 'promptContainer'
  | 'iconContainer'
  | 'iconContainerPrimary'
  | 'iconContainerDanger'
  | 'promptContainerPrimary'
  | 'promptContainerDanger'
  | 'container';

export const useConfirmModalStyles = createUseStyles<RuleNames, unknown, ITheme>((theme: ITheme) => ({
  container: {

  },
  promptContainer: {
    borderRadius: 8,
    padding: '0.875rem',
    display: 'flex',
    alignItems: 'center',
  },
  promptContainerPrimary: {
    composes: '$promptContainer',
    background: '#F5F6F8',
  },
  promptContainerDanger: {
    composes: '$promptContainer',
    background: 'rgba(243, 154, 154, 0.1)',
  },
  iconContainer: {
    width: 45,
    height: 45,
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconContainerPrimary: {
    composes: '$iconContainer',
    background: 'rgba(0, 113, 206, 0.07)',
  },
  iconContainerDanger: {
    composes: '$iconContainer',
    background: 'rgba(243, 154, 154, 0.1)',
  },
  title: {
    color: '#12152A',
    fontSize: '1.25rem',
    fontWeight: '600',
    marginLeft: '0.625rem',
  },
  description: {
    color: '#3B4168',
    fontWeight: '500',
    fontSize: '1rem',
    marginTop: '1rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'start',
    marginTop: '2.25rem',
  },
  button: {
    width: '11.6rem',
    height: '2.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    marginBottom: '0.5rem',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  buttonText: {
    fontSize: '1rem',
    fontWeight: '500',
  },
  primaryButton: {
    composes: '$button',
    background: 'linear-gradient(94.25deg, #007BE0 -0.43%, #0058A0 54.69%)',
    color: '#fff',
    height: 'calc(2.75rem + 2px)',
  },
  cancelButton: {
    composes: '$button',
    boxSizing: 'content-box',
    borderWidth: 1,
    borderStyle: 'solid',
    borderImageSource: 'linear-gradient(94.25deg, #007BE0 -0.43%, #0058A0 54.69%)',
    borderImageSlice: 1,
    borderRadius: 4,
    marginLeft: '1rem',
  },
  dangerButton: {
    composes: '$button',
    background: '#EB5757',
    color: '#fff',
    height: 'calc(2.75rem + 2px)',
  },
  '@media (max-width: 768px)': {
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
    },
    cancelButton: {
      boxSizing: 'border-box',
      width: '100%',
      margin: 0,
    },
    primaryButton: {
      margin: 0,
      width: '100%',
      boxSizing: 'border-box',
    },
    dangerButton: {
      margin: 0,
      width: '100%',
      boxSizing: 'border-box',
    },
  },
}));
