import { FC } from 'react';
import cn from 'classnames/bind';
import { CheckedIcon } from '../icons/checked';
import { ICheckboxType } from './checkbox.model';
import { useCheckboxStyles } from './checkbox.styles';

export const Checkbox: FC<ICheckboxType> = ({ checked, onChange }) => {
  const classes = useCheckboxStyles();
  const cx = cn.bind(classes);

  const handleChange = (): void => {
    if (typeof onChange === 'function') {
      onChange(!checked);
    }
  };

  const checkboxContainerClass = cx({
    containerChecked: checked,
    container: !checked,
  });

  return (
    <div onClick={handleChange} className={checkboxContainerClass}>
      {
        checked && (
          <CheckedIcon />
        )
      }
    </div>
  );
};
