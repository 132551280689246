import { useContext, useState } from 'react';
import cn from 'classnames/bind';
import { observer } from 'mobx-react';
import Dropdown from 'rc-dropdown';
import { appStoreContext } from '../../../../stores/context.store';
import { AddUserIcon } from '../../icons/add-user';
import { SettingsIcon } from '../../icons/settings';
import { useMessengerHeaderStyles } from '../messengerHeader.styles';
import { IProps } from './types';

export const ChatListHeader = observer(({ setIsOpenCreateGroup }: IProps) => {
  const [isGroupVisible, setIsGroupVisible] = useState(false);
  const [chatListTitle, setChatListTitle] = useState(false);
  const appStore = useContext(appStoreContext);
  const chatId = appStore.conversation.chatId;
  const classes = useMessengerHeaderStyles();
  const cx = cn.bind(classes);
  const isGroupVisibleClass = cx({
    isGroup: isGroupVisible,
  });
  new ResizeObserver(() => {
    setIsGroupVisible(chatId !== null && window.innerWidth <= 768);
    setChatListTitle(window.innerWidth <= 768);
  }).observe(document.body);
  return (
    <div className={classes.spaceContainer}>
      <div className={classes.spaceInnerContainer}>
        <h2 className={classes.messengerTitle}>Обращения</h2>
      </div>
    </div>
  );
});
